<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian
        Government
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian
            Government
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Sept 12, 2021</p>
            </span>
          </div>
          <p class="">
            After months of a global lockdown due to the COVID-19 pandemic, the
            world is starting to have a similitude of normalcy thanks to the
            effort of different health organizations across the globe in
            delivering vaccines that guarantees immunity against the virus.
          </p>
          <p>
            First world countries like Great Britain, America, Russia, China and
            others in collaboration with health institutions like WHO, USAID
            etc. are at the forefront of ensuring the world draws the curtain on
            the pandemic for good, with millions of vaccines being donated to
            different countries with limited resources to tackle the virus, a
            world void of the threat the virus poses look more likely than ever.
          </p>
          <p>
            However, for countries like Nigeria, the most populated country in
            Africa, securing the vaccines is first base. Distributing the
            vaccines across the 36 states of the federation requires a more
            technical approach, structure, and infrastructure that could support
            the effective distribution of these vaccines to the last mile.
          </p>
          <p class="grey-text">
            With 36 states spread across a landmass of 923,768km² to cover,
            NPHCDA, the arm of the Nigerian Government tasked with the
            responsibility of getting these vaccines to the last mile has her
            work cut out, ensuring the vaccines gets to each state in the best
            condition was paramount, and being able to attest to the potency of
            the vaccines is crucial, both of which Gricd technologies and
            software guarantees.
          </p>
          <p>
            In August 2021, Nigeria received a donation of 4.2 million doses of
            Moderna vaccines from the US Government. However, unlike Astrazeneca
            vaccines that need to be transported at 2-8 degrees, the Moderna
            vaccines are stored at a much lower temperature. Although upon
            arrival the vaccines were frozen within the appropriate temperature
            range of -50°C and -15°C, the Moderna vaccines are to be stored at
            -15°C to -25°C degrees
          </p>
          <!-- <v-skeleton-loader
            type="card-avatar"
            v-if="loading === true"
            :loading="loading"
          ></v-skeleton-loader> -->
          <!-- <figure class="pt-8">
            <img
              src="../../assets/blog new/image (6).jpg"
              alt=""
              width="100%"
              class=""
              style="max-height: 21rem;object-fit: contain;"
            />
          </figure> -->

          <video
            loop
            playsinline
            controls
            muted
            poster=""
            width="100%"
            height="100%"
            style="object-fit: contain"
            v-show="loaded"
            class="vid-lazy py-6"
            id="vid-el"
          >
            <source
              src="https://res.cloudinary.com/dcjbgkkej/video/upload/v1631629139/gricd-5_wIvlf1HE_ybphaq.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
          <p>
            COVID-19 vaccines must be delivered to millions of Nigerians in both
            rural and urban regions in a short amount of time. It is estimated
            that Nigeria has had 166,098 COVID-19 cases and 2107 fatalities as
            of May 20, 2101. A total of 156,528 people have recovered from the
            coronavirus, according to the NCDC. As a result of vaccines, the
            mortality toll from epidemics, pandemics, and other types of disease
            outbreaks is reduced.
          </p>

          <p>
            Nigeria's Federal Government started distributing its second batch
            of Coronavirus vaccinations, 4.2million Moderna vaccine doses
            provided by the United States of America. The contribution,
            according to the National Primary Health Care Development Agency
            (NPHCDA) at the unveiling on Monday at the Federal Medical Centre in
            Jabi, Abuja, was made to help the country fight COVID-19 infection.
          </p>

          <figure class="pt-8">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626458/mote_ejx2nr.png"
              alt=""
              width="100%"
              class=""
              style="max-height: 21rem;object-fit: contain;"
            />
          </figure>

          <v-skeleton-loader
            type="card-avatar"
            v-if="loading === true"
            :loading="loading"
          ></v-skeleton-loader>

          <p class="pt-5">
            To mitigate waste and ensure optimal potency of the vaccines, the
            National Primary Healthcare Development Agency(NPHCDA) engaged the
            services of Gricd. The potency of Moderna Vaccines is ensured by
            tracking the storage temperatures of the vaccines with the MOTE
            device, a Multi-Purpose Real-time Data Logger by Gricd. MOTE is
            placed in all containers transporting the Moderna. Information like
            the temperature and location of the vaccine are generated remotely
            through Gricd’s Enterprise software. Alerts are sent to the
            authorized persons should the vaccines go off route or out of the
            preset temperature range. Since the temperature of these vaccines is
            periodically inspected, temperature excursions can easily be checked
            for all 4.2 million doses of Moderna Vaccines.
          </p>

          <figure class="pt-8 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626422/pasted_image_0_gzqjtz.png"
              alt=""
              width="100%"
              class=""

            />
          </figure>

          <p>
            Gricd, the brain behind the real-time data logger, MOTE, is a
            leading Cold-chain solution provider in Nigeria. Gricd was
            recognized by CNN as being a major contributor to the distribution
            of COVID-19 vaccines earlier, in the thick of the pandemic. It is
            remarkable to see Gricd working with the federal government in the
            distribution of the second batch of COVID-19 vaccines using its data
            logger, MOTE and its enterprise software.
          </p>

          <p>
            To know more about Gricd’s services, reach out via Info@Gricd.com to
            find out more about our technology. Visit
            <a href="www.gricd.com">gricd.com</a> to find out more.
          </p>
          <p>
            Reference:
            <a
              style="color:blue;text-decoration:underline"
              href="https://www.cdc.gov/vaccines/covid-19/info-by-product/moderna/downloads/storage-summary.pdf"
              target="_blank"
              >https://www.cdc.gov/vaccines/covid-19/info-by-product/moderna/downloads/storage-summary.pdf</a
            >
            to find out more.
          </p>
          <figure class="pt-6">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626431/296A0972_1_xenzoa.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/baby vaccination.jpg';
import image5 from '../../assets/images/covid oon deep oce.jpg';
import image6 from '../../assets/images/covid19-vaccine-waste.jpg';
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name:
                  'Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians',
                item:
                  'https://gricd.com/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians',
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == 'complete') {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener('readystatechange', readyHandler);
      }
    };

    document.addEventListener('readystatechange', readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
</style>
